<template>
  <!-- 销售合同管理 -->
  <div class="salesContractManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          @click="addAmountNotify"
        >
          销售合同回调
        </el-button>
      </div>
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      >
        <template #expiryContractDate="{row}">
          {{ row.expiryContractDate||'-' }}
          <div v-if="row.isOverdue==='1'" class="overdue-box">
            已逾期
          </div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <PdfContract
      :sign-or-not="pdfContractConfig.signOrNot"
      :visible.sync="pdfContractConfig.visible"
      :url="pdfContractConfig.url"
      @confirmSign="confirmSign"
    />
    <!-- 回调的弹窗 -->
    <el-dialog title="合同回调" :visible.sync="amountNotifyVisible" width="45%" :close-on-click-modal="false">
      <el-form ref="amountNotifyForm" class="sales-dialog-form" :model="amountNotifyForm" :rules="rules" label-width="140px">
        <el-form-item
          label="银行订单支付信息"
          prop="bankOrderPayInfoId"
        >
          <el-input v-model="amountNotifyForm.bankOrderPayInfoId" placeholder="请输入银行订单支付信息" autocomplete="off" />
        </el-form-item>
        <el-form-item
          label="订单状态"
          prop="status"
        >
          <el-select v-model="amountNotifyForm.status" placeholder="请选择">
            <el-option
              label="支付成功"
              value="2"
            />
            <el-option
              label="支付失败"
              value="3"
            />
            <el-option
              label="已取消"
              value="4"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="widen-button" size="small" @click="amountNotifyVisible = false">
          取 消
        </el-button>
        <el-button class="widen-button" type="primary" size="small" @click="addAmountNotifyOk">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import PdfContract from '@/components/PdfContract'
// import { rules, formPattern } from '@/unit/matchFormRegex'
import { agentPurchaseContractPage, agentPurchaseContractSynPurContract, abcBailPayAddAmountNotify } from '@/api/generationMining'
import { roundUpToInteger } from '@/utils/util'
import { agentPurchaseContractGetEsignUrl } from '@/api/generationMining'
export default {
  components: {
    FormSearch,
    PdfContract,
    Table,
    Pagination
  },
  data() {
    return {
      amountNotifyForm: {},
      amountNotifyVisible: false,
      rules: {
        bankOrderPayInfoId: [{ required: true, message: '请输入', trigger: 'blur' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editFormData: {},
      dialogTableVisible: false,
      synchronizingContractData: {
        purchaseFileUrl: ''
      },
      addAndEditFormItem: [{
        label: '审批类型',
        type: 'radio',
        child: [{ value: 1, label: '系统审批' }, { value: 2, label: '钉钉审批' }],
        value: 'oaType'
      }],
      pdfContractConfig: { visible: false, url: '' },
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '销售合同', value: 'contractNo', width: '120' },
        { type: 'input', label: '经销商', value: 'custCmpName', width: '120' },
        { type: 'input', label: '代采商', value: 'fundsCmpName', width: '120' },
        { type: 'input', label: '合作企业', width: '120', value: 'supplierCmpName' },
        { type: 'select', label: '合同状态', value: 'contractStatus', width: '120', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('contractStatus') },
        { type: 'radio', label: '是否逾期', value: 'isOverdue', width: '120', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '是', dictId: '1' }, { dictName: '否', dictId: '0' }] }
      ],
      itemData: [
        { label: '销售合同号', prop: 'contractNo', width: 150 },
        // { label: '采购计划编号', prop: 'planNo', width: 180 },
        { label: '经销商', prop: 'custCmpName', width: 180 },
        { label: '代采商', prop: 'fundsCmpName', width: 240 },
        { label: '合作企业', prop: 'supplierCmpName', width: 240 },
        { label: '合同总额(元)', prop: 'aggregateAmount', width: 180, type: 'money' },
        { label: '保证金(元)', prop: 'depositPayable', width: 150, type: 'money' },
        // { label: '代采费率', prop: 'agentRate', width: 150 },
        { label: '合同到期日', prop: 'expiryContractDate', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        // { label: '付款状态', prop: 'paymentStatus', width: 160, child: this.$store.getters.getDictionaryItem('paymentStatus') },
        { label: '合同状态', prop: 'contractStatus', width: 160, child: this.$store.getters.getDictionaryItem('contractStatus') }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '查看协议', handleEvent: this.paymentBondTable, childOne: [{ num: 3, val: 'contractStatus' }, { num: 4, val: 'contractStatus' }, { num: 5, val: 'contractStatus' }, { num: 6, val: 'contractStatus' }, { num: 7, val: 'contractStatus' }, { num: 8, val: 'contractStatus' }, { num: 9, val: 'contractStatus' }, { num: 10, val: 'contractStatus' }, { num: 11, val: 'contractStatus' }, { num: 12, val: 'contractStatus' }] },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      submitId: '',
      total: 0
    }
  },
  methods: {
    // // 确认签署
    confirmSign() {
      agentPurchaseContractGetEsignUrl({ contractId: this.pdfContractConfig.id, operFlat: 3 }
        , res => {
          this.$message.success('成功！')
          this.pdfContractConfig.visible = false
          window.open(res.data)
        })
    },
    // 获取上传的文件名字跟URL
    getAgentFile(file) {
      this.synchronizingContractData.purchaseFileName = file.fileName
      this.synchronizingContractData.purchaseFileUrl = file.fileUrl
      this.$refs['purchaseFileUrl'].clearValidate()
      this.$forceUpdate()
    },
    // 点击确定按钮  进行同步
    synchronization() {
      this.$refs['fromInline'].validate((valid) => {
        if (valid) {
          if (!this.synchronizingContractData.purchaseFileUrl) {
            this.$message.error('请上传文件')
            return
          }
          agentPurchaseContractSynPurContract(this.synchronizingContractData, () => {
            this.$message.success('成功！')
            this.getdata(true)
            this.dialogTableVisible = false
          })
        }
      })
    },
    // 获取列表
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchaseContractPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.aggregateAmount = roundUpToInteger((Number(item.totalPayment) || 0) + (Number(item.estimateCost) || 0))
          if (item.completionStatus === 0 && item.contractStatus === 6) {
            item.invStatus = true
            return
          }
          item.invStatus = false
        })
        this.total = res.data.total
      })
    },
    // 同步采购合同
    async simultaneousPurchaseContract(row) {
      this.dialogTableVisible = true
      this.synchronizingContractData.id = row.id
    },
    // 添加
    addRow() {
      this.$router.push({ path: '/salesContractManagement/addSalesContract', query: { type: true }})
    },
    // 查看协议
    paymentBondTable(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.contractFileUrl,
        signOrNot: false
      }
    },
    // 垫资付款
    async matEndowmentPayment(row) {
      this.editFormData.id = row.id
      this.editFormData.type = true
      this.$refs.dialog.editFormVisible = true
      this.$refs.dialog.dialogTitle = '申请用印'
    },
    // 协议签署
    agreementSigning(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.contractFileUrl,
        id: row.id,
        signOrNot: true
      }
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({ path: '/salesContractManagement/salesContractDetails', query: { rowId: row.id }})
    },
    // 回调
    addAmountNotify() {
      this.amountNotifyVisible = true
    },
    // 确认
    addAmountNotifyOk() {
      this.$refs['amountNotifyForm'].validate((valid) => {
        if (valid) {
          abcBailPayAddAmountNotify(this.amountNotifyForm, res => {
            this.$message.success('回调成功！')
            this.amountNotifyVisible = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.salesContractManagement {
  .overdue-box{
    text-align: center;
    line-height: 22px;
    height: 22px;
    width: 54px;
    border-radius: 6px;
    font-size: 14px;
    background-color: $assistColor5;
    color: #fff;
  }
  .sales-dialog-form {
    padding: 0 62px 0 28px;
  }

  .dialog-footer {
    text-align: center;
  }
}
</style>
